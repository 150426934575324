.guides-main {
    display: flex;

    .guides-main-wrapper {
        padding: 40px;
        width: 100%;
        padding-bottom: 50vh;
    }

    section {
        margin: 40px 0;
        scroll-padding: 200px;

        ul {
            padding: 0;
            margin: 0;
            max-width: 600px;
            list-style-type: none;

            li {
                details {
                    scroll-snap-align: start;
                    border-bottom: 1px solid #e5e5e5;

                    &[open] {
                        padding-bottom: 20px;

                        summary {
                            &::after {
                                background: var(--mango-green);
                                border-radius: 500px;
                                transform: scale(0.25);
                                opacity: 1;
                            }
                        }
                    }
                }

                summary {
                    outline-width: 2px;
                    outline-color: #eee;
                    padding: 15px 0;
                    display: flex;
                    list-style: none;
                    cursor: pointer;
                    background-color: #fff;
                    position: relative;
                    user-select: none;

                    &::after {
                        --size: 24px;
                        content: "";
                        position: absolute;
                        height: var(--size);
                        width: var(--size);
                        right: 10px;
                        background: url("../../images/icons/arrow_right.svg");
                        transform: rotate(90deg);
                        opacity: 0.25;
                    }

                    &::-webkit-details-marker {
                        display: none;
                    }
                }

                h4 {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.5;
                }

                p {
                    margin: 0;
                    padding: 0;
                    line-height: 1.5;
                    font-size: 14px;
                    font-weight: 400;
                }

                p + p{
                    padding: 10px 0;
                }

                .key {
                    font-size: 10px;
                    text-transform: uppercase;
                    letter-spacing: 0.35px;
                    padding: 4px 6px;
                    margin: 6px 4px;
                    border-radius: 5px;
                    background-color: #fff;
                    font-weight: 600;
                    box-shadow: 0 1px 1px 1px #aaa;
                    vertical-align: text-top;
                    white-space: nowrap;
                }

                blockquote {
                    border-left: 2px solid #000;
                    background-color: #f5f5f5;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    margin: 0;
                    padding: 1em;
                    margin: 1em 0;
                    font-size: 14px;
                }

                table {
                    margin: 20px 0;
                    font-size: 14px;

                    thead {
                        font-weight: 500;

                        @media (max-width: 1000px) {
                            tr {
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);
                            }
                        }

                        td {
                            padding: 0 10px;
                        }
                    }

                    td {
                        padding: 10px;

                        p {
                            padding: 0;
                        }
                    }

                    tr:nth-child(even) {
                        td {
                            background-color: #f5f5f5;
                        }
                    }

                    @media (max-width: 1000px) {
                        max-width: 100%;
                        overflow-x: scroll;
                        display: grid;
                    }
                }
            }
        }
    }
}
