.home-main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .home-introduction-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1000px;
        font-family: var(--inter);
        font-weight: 400;

        .home-introduction {
            padding: 6rem;

            h1 {
                --line-height: 1.25;
                overflow: hidden;
                line-height: var(--line-height);

                .line-two {
                    display: block;
                    white-space: nowrap;
                }

                .blocks {
                    display: inline-flex;
                    flex-direction: column;
                    height: calc(var(--line-height) * 1em);
                    overflow: hidden;

                    .block {
                        --animation-speed: 10s;
                        padding-left: 8px;
                        white-space: nowrap;
                        animation: block-transform var(--animation-speed) steps(4) infinite,
                            block-blink 2.5s ease infinite 2.25s;

                        @keyframes block-transform {
                            0% {
                                transform: translateY(0);
                            }
                            100% {
                                transform: translateY(-400%);
                            }
                        }

                        @keyframes block-blink {
                            0% {
                                opacity: 1;
                            }
                            10% {
                                opacity: 0;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            @media (max-width: 1400px) {
                padding: 4rem;
            }

            @media (max-width: 1000px) {
                padding: 4rem 3rem;
            }

            h2 {
                margin-top: 20px;

                u {
                    text-underline-position: below;
                }
            }

            ul {
                margin: 0;
                margin-top: 40px;
                list-style-type: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 12px;

                li {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    border-radius: 50px;
                    background: linear-gradient(to right, #f5f5f5, #fff);
                    padding: 10px;
                    padding-left: 20px;
                    will-change: transform, opacity;

                    @media (max-width: 1000px) {
                        padding: 8px;
                        padding-left: 14px;
                        gap: 12px;
                    }

                    img {
                        --size: 32px;
                        height: var(--size);
                        width: var(--size);
                        pointer-events: none;
                        user-select: none;

                        @media (max-width: 1000px) {
                            --size: 22px;
                        }
                    }

                    p {
                        font-size: 16px;
                        font-weight: 500;
                        padding: 0;
                        margin: 0;
                        outline: none;
                        line-height: 1.35;

                        @media (max-width: 1000px) {
                            font-size: 14px;
                            line-height: 1.4;
                        }
                    }

                    .cursor {
                        animation: blink 0.4s ease alternate infinite;

                        @keyframes blink {
                            0% {
                                opacity: 0;
                            }
                            50% {
                                opacity: 1;
                            }
                            100% {
                                opacity: 0;
                            }
                        }
                    }
                }
            }

            .start-btn {
                outline: none;
                margin-top: 60px;
                color: #fff;
                background-color: var(--mango-green);
                text-decoration: none;
                padding: 30px 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: fit-content;
                font-family: var(--inter);
                border-radius: 500px;
                font-weight: 600;
                font-size: 15px;
                user-select: none;
                outline-offset: 4px;
                outline: 2px solid #fff;
                transition: all 0.8s var(--bounce-ease-1);

                &:hover,
                &:focus {
                    transform: scale(1.025);
                }

                &:focus-visible {
                    outline: 2px solid #aaa;
                }

                &:active {
                    transform: scale(0.95);
                }

                @media (max-width: 1000px) {
                    width: 100%;
                    max-width: 400px;
                    padding: 20px 30px;
                }
            }

            .start-btn-description {
                font-size: 14px;
                margin-top: 20px;
                max-width: fit-content;
                padding-left: 10px;

                @media (max-width: 1000px) {
                    width: 100%;
                    max-width: 400px;
                    text-align: center;
                }
            }
        }
    }

    .home-preview {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: var(--mango-orange);
        height: 100vh;

        @media (min-width: 1000px) {
            &:hover {
                video {
                    transform: scale(1.05);
                }
            }
        }

        @media (max-width: 1000px) {
            height: auto;
            padding: 6rem 2rem;
        }

        video {
            background-color: var(--mango-orange);
            width: 100%;
            max-width: 85%;
            border-radius: 20px;
            box-shadow: 0 10px 40px 0 var(--mango-orange-dark);
            transition: all 0.6s ease;
            pointer-events: none;

            @media (max-width: 1000px) {
                max-width: 100%;
                border-radius: 10px;
            }
        }
    }

    .greeting {
        padding: 4rem;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: var(--ibm-plex-serif);
        font-weight: 500;
        letter-spacing: -0.25px;

        img {
            height: 1.5em;
            pointer-events: none;
            user-select: none;
        }

        @media (max-width: 1000px) {
            padding: 2rem;
        }
    }
}
