.insert-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    outline: none;
    padding: 4px 0;

    &.always-visible,
    &:hover,
    &:focus-within {
        &::after {
            opacity: 1;
        }
        .insert-button {
            opacity: 1;
        }
    }

    &:focus-within {
        &::after {
            background: linear-gradient(to right, #fff, #000, #fff);
            background-size: 150%;
            background-position: 50%;
        }
        .insert-menu-list {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &.hidden {
        opacity: 0;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #fff, #aaa, #fff);
        opacity: 0;
        transition: background-size 0.2s ease-out, background-position 0.2s ease-out;
    }

    .insert-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        border: none;
        cursor: pointer;
        padding: 6px 20px;
        border-radius: 500px;
        opacity: 0;
        z-index: 1;
        outline: none;
        box-shadow: 0 0 0 4px #fff;
        will-change: transform;
        transition: transform 0.6s var(--bounce-ease-1);

        img {
            height: 12px;
            width: 12px;
            transition: transform 1s var(--bounce-ease-1);
        }

        &:hover {
            opacity: 1;
            transform: scale(1.2);
        }

        &:focus {
            opacity: 1;
        }
    }

    .insert-menu-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.85);
        backdrop-filter: blur(30px) saturate(180%);
        position: absolute;
        z-index: 5;
        top: 0;
        opacity: 0;
        pointer-events: none;

        &.visible {
            opacity: 1;
            pointer-events: auto;
        }

        .list-group-divider {
            width: 100%;
            height: 1px;
            background-color: #555;
            margin: 4px 0;
        }

        .list-item {
            --element-list-item: 0;
            display: flex;
            width: 100%;
            align-items: center;
            padding: 5px 4px;
            padding-left: 36px;
            border-radius: 5px;
            cursor: pointer;
            border: none;
            margin: 0;
            color: #ddd;
            min-width: 300px;
            font-size: 14px;
            background-color: rgba(0, 0, 0, 0);
            outline: none;
            position: relative;

            &:nth-of-type(1) {
                --element-list-item: 0;
            }
            &:nth-of-type(2) {
                --element-list-item: 1;
            }
            &:nth-of-type(3) {
                --element-list-item: 2;
            }
            &:nth-of-type(4) {
                --element-list-item: 3;
            }
            &:nth-of-type(5) {
                --element-list-item: 4;
            }
            &:nth-of-type(6) {
                --element-list-item: 5;
            }
            &:nth-of-type(7) {
                --element-list-item: 6;
            }
            &:nth-of-type(8) {
                --element-list-item: 7;
            }
            &:nth-of-type(9) {
                --element-list-item: 8;
            }
            &:nth-of-type(10) {
                --element-list-item: 9;
            }
            &:nth-of-type(11) {
                --element-list-item: 10;
            }
            &:nth-of-type(12) {
                --element-list-item: 11;
            }
            &:nth-of-type(13) {
                --element-list-item: 12;
            }
            &:nth-of-type(14) {
                --element-list-item: 13;
            }

            &:hover,
            &:focus {
                background-color: rgba(0, 0, 0, 0.5);
                color: #fff;
            }

            &:active {
                background-color: rgba(0, 0, 0, 0.85);
                color: #fff;
            }

            &::before {
                content: "";
                position: absolute;
                left: 4px;
                height: 24px;
                width: 24px;
                background-image: url("../../../images/icons/element_icons.svg");
                background-position: calc((-24px * var(--element-list-item)) + (-10px * var(--element-list-item))) 0;
                opacity: 1;
                transform: scale(0.7);
            }

            &:hover::after {
                content: "";
                position: absolute;
                right: 0;
                height: 100%;
                width: 24px;
                background-image: url("../../../images/icons/add.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 12px;
                opacity: 0.8;
            }

            &:active::after {
                opacity: 1;
            }
        }
    }
}
