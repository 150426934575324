.login-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4rem;
    padding-top: 100px;
    padding-bottom: 25vh;

    .login-block-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 400px;
        aspect-ratio: 1;
        padding: 2rem 3rem;
        border: 1px solid #ddd;
        border-radius: 20px;

        button {
            margin-top: 20px;
            border: none;
            background-color: var(--mango-green);
            color: #fff;
            font-size: 13px;
            font-family: var(--inter);
            border-radius: 500px;
            padding: 16px 24px;
            cursor: pointer;
            max-width: fit-content;
            font-weight: 500;
            user-select: none;
            transition: all 0.2s var(--bounce-ease-1);

            &:not(:disabled) {
                &:hover {
                    transform: scale(1.025);
                }

                &:focus {
                    transform: scale(1.025);
                    outline: 2px solid #ddd;
                    outline-offset: 2px;
                }

                &:active {
                    transform: scale(0.975);
                }
            }

            &.loading {
                cursor: wait;

                span {
                    &::after {
                        content: "Sign in with Google|";
                        animation: sign-in-button-intro 1s linear forwards;

                        @keyframes sign-in-button-intro {
                            0% {
                                content: "Sign in with Google";
                            }
                            5% {
                                content: "Sign in with Google|";
                            }
                            10% {
                                content: "Sign in with Googl|";
                            }
                            15% {
                                content: "Sign in with Goo|";
                            }
                            20% {
                                content: "Sign in with |";
                            }
                            25% {
                                content: "Sign in w|";
                            }
                            30% {
                                content: "Sign|";
                            }
                            35% {
                                content: "Sign";
                            }
                            40% {
                                content: "Signi|";
                            }
                            45% {
                                content: "Signin|";
                            }
                            50% {
                                content: "Signing|";
                            }
                            55% {
                                content: "Signing y|";
                            }
                            60% {
                                content: "Signing yo|";
                            }
                            65% {
                                content: "Signing you|";
                            }
                            70% {
                                content: "Signing you |";
                            }
                            75% {
                                content: "Signing you i|";
                            }
                            80% {
                                content: "Signing you in";
                            }
                            85% {
                                content: "Signing you in.|";
                            }
                            90% {
                                content: "Signing you in..|";
                            }
                            95% {
                                content: "Signing you in...|";
                            }
                            100% {
                                content: "Signing you in...";
                            }
                        }
                    }
                }
            }
        }
    }
}
