.divider-group {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    z-index: 1;

    label {
        font-size: 9px;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 1;
        font-weight: 600;
        background-color: #fff;
        padding-right: 4px;
        opacity: 0;
    }

    .divider {
        display: flex;
        outline: none;
        width: 100%;
        position: absolute;
        z-index: -1;
        background-color: #aaa;
        height: 1px;
        transform-origin: left;
        will-change: transform;
    }

    .element-actions {
        opacity: 0;
    }

    &:hover {
        .element-actions {
            opacity: 1;
        }
    }
}
