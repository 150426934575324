.support-main {
    display: flex;

    .support-main-wrapper {
        padding: 40px;
        width: 100%;
        padding-bottom: 50vh;

        h1 {
            img {
                height: 1.25em;
                vertical-align: text-bottom;
                pointer-events: none;
                user-select: none;
            }
        }

        .support-blocks {
            margin-top: 60px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;

            @media (max-width: 1000px) {
                grid-template-columns: 1fr;
            }

            .support-block {
                background-color: #f5f5f5;
                height: 100%;
                border-radius: 20px;
                font-family: var(--ibm-plex-serif);
                padding: 40px;
                padding-bottom: 30px;
                font-size: 24px;
                letter-spacing: -1px;
                text-decoration: none;
                display: flex;
                flex-direction: column;
                gap: 60px;
                align-items: flex-start;
                justify-content: space-between;
                outline-color: #ddd;
                outline-offset: 2px;
                transition: all 0.2s var(--bounce-ease-1);
                box-shadow: 0 0 0 0px #f5f5f5;

                &:hover {
                    text-decoration: underline;
                    box-shadow: 0 0 0 10px #f5f5f5;

                    .support-block-label::after {
                        opacity: 1;
                    }
                }

                @media (max-width: 1000px) {
                    aspect-ratio: 3 / 2;
                    width: 100%;
                    max-height: 300px;
                    padding: 20px;
                    font-size: 22px;
                    gap: 20px;
                    border-radius: 10px;
                    height: auto;
                }

                .support-block-label {
                    width: 100%;
                    position: relative;
                    display: flex;
                    align-items: flex-start;

                    &::after {
                        --icon-size: 28px;
                        position: absolute;
                        content: "";
                        height: var(--icon-size);
                        width: var(--icon-size);
                        right: 0;
                        background-image: url("../../images/icons/arrow_right.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                }

                .support-block-art {
                    position: relative;
                    --size: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: var(--size);
                    width: var(--size);

                    img {
                        height: 100%;
                        pointer-events: none;
                        user-select: none;
                    }

                    @media (max-width: 1400px) {
                        --size: 130px;
                        align-self: flex-end;
                    }
                    @media (max-width: 1000px) {
                        --size: 80px;
                    }
                }
            }
        }
    }
}
