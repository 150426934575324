.preview-toggle-block {
    --width: 35px;
    height: 100vh;
    width: var(--width);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    cursor: e-resize;
    background-color: #fff;
    z-index: 11;

    &.active {
        --width: 20px;
        background-color: rgba(255, 255, 255, 0);
        left: calc(50% - (var(--width) / 2));
        cursor: w-resize;

        .preview-icon {
            display: none;
        }

        .divider {
            display: flex;
            right: auto;
        }
    }

    &:hover {
        .divider {
            width: 2px;
            background-color: #999;
        }

        .preview-icon {
            transform: scale(1.25);
        }
    }

    @media (max-width: 1000px) {
        display: none;
    }
}

.preview-icon {
    height: 24px;
    width: 24px;
    display: flex;
    background-image: url("../../images/emojis/heart_hands.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.4s cubic-bezier(0.1, 0.17, 0.16, 1.31);
    cursor: pointer;
}

.divider {
    height: 100%;
    width: 1px;
    background-color: #ddd;
    position: absolute;
    transition: width 0.2s ease;
    right: 0;
}
