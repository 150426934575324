@font-face {
    font-family: "Drafting Regular";
    src: url("../../fonts/drafting/DraftingMono-Regular.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Drafting Bold";
    src: url("../../fonts/drafting/DraftingMono-Bold.ttf") format("truetype");
    font-style: normal;
}

main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 1000px) {
        display: none;
    }

    &.editor-only {
        grid-template-columns: 1fr;
        justify-items: center;

        .preview {
            position: fixed;
            z-index: -1;
            pointer-events: none;
            margin: 0 auto;
            width: 100%;
            max-width: calc(880px - (40px * 2));
            opacity: 0;
        }
    }
}

.header-block {
    width: 100%;
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    padding-bottom: 0;

    &.preview-header {
        h2 {
            cursor: pointer;
        }
    }

    &.editor-header {
        .header-title-text::after {
            background-image: url("../../images/emojis/writing_hand.png");
        }
    }

    .title-group {
        display: flex;
        flex-direction: column;

        .preview-title-tabs {
            --title-tab-gap: 8px;
            display: flex;
            align-items: center;
            padding-top: 10px;

            h2 {
                width: auto;
            }

            .preview-title-tab {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                color: #000;
                opacity: 0.35;
                transition: opacity 0.25s ease-out;

                &.active {
                    opacity: 1;
                }

                &:nth-child(1) {
                    position: relative;
                    padding-right: var(--title-tab-gap);

                    &::after {
                        content: "";
                        height: 50%;
                        width: 1px;
                        background-color: #aaa;
                        position: absolute;
                        right: 0;
                    }
                }

                &:nth-child(2) {
                    padding-left: var(--title-tab-gap);
                }
            }
        }

        .saving-status {
            display: flex;
            color: #555;
        }
    }

    h2 {
        user-select: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        padding-top: 10px;
        padding-bottom: 2px;
        width: 100%;
        overflow: hidden;

        .header-title-text {
            display: flex;
            gap: 4px;
            align-items: center;

            &::after {
                content: "";
                height: 1em;
                width: 1em;
                display: inline-flex;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}

.section {
    user-select: none;
    height: calc(100vh - var(--header-height));
    padding: 40px;
    padding-top: 0;
    padding-bottom: 75vh;
    scroll-padding: 200px;
    position: relative;
    background-color: #fff;
    overflow-y: scroll;

    // hide scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.preview {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 40px;
    font-size: 14px;

    .preview-contents {
        user-select: all;
        line-height: 1.5;

        &.draft {
            font-family: "Drafting Regular";

            .preview-block-label {
                font-family: "Drafting Bold";
                font-variant: small-caps;
            }
        }

        p {
            overflow-wrap: break-word;
        }

        &:empty {
            height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;

            &::after {
                content: "";
                display: flex;
                width: 100%;
                height: 30px;
                background-image: url("../../images/meow.gif");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 30px;
                opacity: 0;
                transform: translateY(5px);
                will-change: opacity, transform;
                animation: empty-intro 1s ease forwards;
            }
        }

        @keyframes empty-intro {
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .preview-block {
            padding: 0;
            white-space: pre-wrap;
            word-break: break-word;
            color: #333;
            margin: 1em 0;

            .preview-block-label {
                font-weight: 700;
                color: #000;
                display: inline-block;
            }

            &.preview-block-title {
                font-size: 30px;
                font-weight: 700;
                letter-spacing: -1px;
                line-height: 1.15;
                margin-bottom: 0.75em;
            }

            &.preview-block-subtitle {
                font-size: 20px;
                font-weight: 500;
                letter-spacing: -0.5px;
                line-height: 1.25;
            }

            &.preview-block-title + &.preview-block-subtitle {
                margin-top: 0;
                margin-bottom: 2em;
            }

            &.preview-block-heading {
                font-size: 22px;
                font-weight: 700;
                letter-spacing: -0.35px;
                line-height: 1.25;
                margin: 0.5em 0;
            }

            &.preview-block-subheading {
                font-size: 18px;
                letter-spacing: -0.25px;
                font-weight: 600;
                line-height: 1.25;
            }

            &.preview-block + .preview-block-heading {
                margin-top: 2em;
                margin-bottom: 0;
            }

            &.preview-block-heading + .preview-block-subheading {
                margin-top: 0.35em;
                margin-bottom: 1em;
            }

            &.preview-block-subheading + .preview-block-paragraph {
                margin-top: 1.5em;
            }

            &.preview-block-paragraph {
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.1px;
                line-height: 1.45;
            }

            &.preview-block-cta {
                font-size: 14px;
                font-weight: 700;
                letter-spacing: -0.15px;
                color: #000;
                padding: 0.5em 0.75em;
                background-color: #f1f1f1;
                margin: 1.5em 0;

                .cta-arrow {
                    font-size: 1.25em;
                }
            }

            &.preview-block-blockquote {
                margin: 2.5em 0;
                font-family: var(--ibm-plex-serif), Georgia, serif;
                font-style: italic;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: -0.15px;
                line-height: 1.35;
                border-left: 3px solid #000;
                padding: 0.2em 1em;
            }

            &.preview-block-image-description {
                font-size: 12px;
                color: #555;
                margin: 0;
            }
        }

        .image-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 8px;
            margin-bottom: 5px;

            a {
                display: flex;
            }

            img {
                max-width: 100%;
                max-height: 40vh;
                min-height: 100px;
                min-width: 200px;
                display: block;
                border-radius: 15px;
                box-shadow: 0 0 0 0 #eee;
                transition: box-shadow 0.1s ease;

                &:hover {
                    box-shadow: 0 0 0 3px #eee;
                }

                &:active {
                    box-shadow: 0 0 0 3px #ddd;
                }
            }
        }

        .note-block {
            margin-top: 2px;
        }

        .link-block {
            .link-block-favicon {
                height: 1em;
                width: 1em;
                vertical-align: middle;
                margin: 0 4px 1px 4px;
                display: inline;
            }
            a {
                white-space: pre-wrap;
                word-break: break-all;
                outline: none;
                color: #000;
            }

            &:hover,
            &:focus {
                a {
                    background-color: #eee;
                }
            }
        }

        .preview-block-link {
            a {
                font-size: 14px;
                font-weight: 700;
                text-decoration: underline;
                color: #1155cc;
            }
        }

        .list-block {
            ol,
            ul {
                margin: 0;
                padding: 0;
                list-style-position: inside;
            }
            li {
                margin: 0;
                padding: 0;
                text-align: left;
                line-height: 1.65;
            }
        }

        .note-block {
            color: #888;
            font-size: 12px;
        }

        .divider-block {
            margin-top: 0;
        }
    }
}

.editor {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    position: relative;
    width: 100%;

    &.solo {
        --solo-size: 850px;
        padding-left: calc((100% - var(--solo-size)) / 2);
        padding-right: calc((100% - var(--solo-size)) / 2);

        @media (max-width: 1000px) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .input-group {
        &:first-of-type {
            .label-group {
                label {
                    padding-bottom: 4px;
                }
            }
        }
        &:last-of-type {
            .insert-group {
                opacity: 1;
            }
        }
    }
}

.actions {
    display: flex;
    gap: 10px;
}

.primary-button {
    white-space: nowrap;
    display: flex;
    align-items: center;
    user-select: none;
    background-color: var(--mango-green);
    color: #fff;
    border: none;
    outline: none;
    padding: 8px 16px;
    border-radius: 500px;
    font-size: 12px;
    letter-spacing: 0.2px;
    font-weight: 500;
    cursor: pointer;
    will-change: transform;
    transition: all 1s cubic-bezier(0, -0.01, 0, 1.31);

    &:hover,
    &:focus {
        transform: scale(1.2);
    }

    &:active {
        transform: scale(0.9);
    }

    &:focus {
        box-shadow: 0 0 0 2px #fff, 0 0 0 4px #aaa;
        filter: brightness(1.1);
    }
}

.reset-button {
    background-color: rgba(255, 255, 255, 0);
    color: #555;
    padding: 6px 8px;

    &:hover,
    &:focus {
        background-color: rgb(175, 0, 0);
        color: rgb(251, 241, 241);
    }
}

.insert-menu-wrapper {
    position: fixed;
    bottom: 0;
    width: 40%;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 11;

    .insert-menu {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        padding: 5px;
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%),
            0 -6px 16px -6px rgb(0 0 0 / 3%);

        .insert-menu-button {
            user-select: none;
            background-color: #fff;
            color: rgb(0, 0, 0);
            border: 1px solid #eee;
            outline: none;
            width: 90px;
            padding: 20px 10px;
            border-radius: 10px;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
            will-change: transform;
            transition: all 1s cubic-bezier(0, -0.01, 0, 1.31);
            position: relative;

            &:hover {
                background-color: #eee;
                color: #000;
            }

            &:active {
                transform: scale(0.8);
            }

            &:focus {
                filter: brightness(0.9);
            }
        }
    }
}

.copy-button {
    position: relative;
    overflow: hidden;
    display: flex;

    &.copied {
        transform: scale(1.25);

        &::after {
            content: "Copied";
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--mango-green);
            color: #fff;
            animation: copied-intro 2s cubic-bezier(0.44, 0.17, 0.16, 1.26);
        }

        @keyframes copied-intro {
            0% {
                opacity: 0;
                transform: translateY(20%);
            }
            10% {
                opacity: 1;
                transform: translateY(0%);
            }
            75% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }
}

.snackbar-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;
    padding: 40px 0;
    user-select: none;
    pointer-events: none;

    .snackbar {
        min-width: 300px;
        pointer-events: all;
        border-radius: 500px;
        padding: 10px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        background-color: #fff;
        box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
            inset 0 0 0 1px rgba(255, 255, 255, 0.5);

        .snackbar-message {
            font-family: var(--inter);
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.2px;
        }

        .snackbar-actions {
            display: flex;
            align-items: center;
            gap: 0;
        }

        .snackbar-action-button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--inter);
            padding: 10px;
            border-radius: 500px;
            font-weight: 600;
            font-size: 12px;
            border: none;
            cursor: pointer;
            border-radius: 500px;
            color: #000;
            background-color: #fff;
            will-change: transform;
            transition: all 1s cubic-bezier(0, -0.01, 0, 1.31);

            &:hover {
                background-color: #eee;
            }

            &:active {
                background-color: #ddd;
                box-shadow: 0 0 0 2px #aaa;
                transform: scale(0.85);
            }

            img {
                pointer-events: none;
                user-select: none;
                height: 16px;
                width: 16px;
            }
        }
    }
}

.icon-button {
    --size: 18px;
    height: var(--size);
    width: var(--size);
    padding: 0;
    background-color: #fff;
    color: #000;
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 500px;
    padding: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: transform;
    transition: all 0.2s cubic-bezier(0, -0.01, 0, 1.31);

    &:last-child {
        &:hover:not(:active) {
            &::before {
                transform-origin: bottom right;
                right: 0;
            }
        }
    }

    &:hover:not(:active) {
        position: relative;

        &::before {
            content: attr(data-tooltip);
            white-space: nowrap;
            position: absolute;
            bottom: 100%;
            background-color: rgba(0, 0, 0, 0.85);
            color: #fff;
            padding: 6px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            border-radius: 500px;
            transform: scale(0.5) translateY(-25%);
            transform-origin: bottom;
            will-change: transform, opacity;
            opacity: 0;
            animation: icon-button-tooltip-intro 0.2s ease-out forwards;
            animation-delay: 0.2s;

            @keyframes icon-button-tooltip-intro {
                to {
                    opacity: 1;
                }
            }
        }
    }

    &.active:hover:not(:active) {
        &::before {
            animation-delay: 0.1s;
        }
    }

    img {
        width: 14px;
        height: 14px;
        user-select: none;
        pointer-events: none;
        opacity: 0.5;
        will-change: transform;
        transition: all 1s cubic-bezier(0, -0.01, 0, 1.31);
    }

    &:hover {
        background-color: #eee;
        transform: scale(2);
        box-shadow: 0 0 0 2px #fff;
        z-index: 1;

        img {
            opacity: 1;
            transform: scale(0.8);
        }
    }

    &:active {
        transform: scale(1.5);
    }

    &.copy-text-button {
        &:hover {
            background-color: var(--mango-green-light);
        }
    }

    &.remove-button {
        &:hover {
            background-color: #f3b6b6;
        }
    }

    &.hide-button {
        &:hover {
            background-color: var(--mango-orange-light);
        }

        &.active {
            img {
                opacity: 1;
            }
        }
    }
}
