.input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 8px;
    --focus-shadow: 0 0 0 4px #f5f5f5;
    box-shadow: 0 0 0 1px #e5e5e5;
    background-color: #fff;
    will-change: transform;
    transition: transform 0.1s ease, box-shadow 0.2s ease;

    .element-actions {
        will-change: opacity;
        opacity: 0;
        padding-right: 10px;
        padding-top: 6px;
        height: 100%;
        display: flex;
        gap: 8px;
        transition: all 0.4s cubic-bezier(0, -0.01, 0, 1.31);

        &.fade-out {
            opacity: 0;
            transform: translate3d(0, -5%, 0);
        }
    }

    &:hover {
        box-shadow: 0 0 0 1px #aaa;
    }

    &:focus-within {
        box-shadow: 0 0 0 1px #888, var(--focus-shadow);
    }

    &:hover,
    &:focus-within {
        .element-actions {
            opacity: 1;

            &.fade-out {
                opacity: 0;
            }
        }

        .label-group {
            .drag-handle {
                opacity: 1;
            }
        }
    }

    label,
    .word-count,
    .hidden-state {
        font-size: 9px;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 1;
        font-weight: 600;
    }

    .label-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;

        .drag-handle {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            cursor: grab;

            &::after {
                content: "";
                width: 30px;
                height: 3px;
                border-radius: 500px;
                background-color: #ddd;
            }
        }

        &:active {
            .drag-handle {
                cursor: grabbing;
            }
        }

        label {
            border-radius: 8px;
            display: flex;
            gap: 2px;
            align-items: center;
            width: 100%;
            padding: 12px;
            padding-bottom: 6px;
            color: #000;

            .word-count,
            .hidden-state {
                color: #999;

                &:empty {
                    display: none;
                }

                &::before {
                    content: "∙";
                    letter-spacing: 2px;
                    color: #333;
                }
            }
        }
    }

    textarea,
    input {
        width: 100%;
        font-family: var(--inter);
        padding: 8px 12px;
        padding-top: 2px;
        border-radius: 8px;
        font-size: 14px;
        resize: none;
        border: none;
        outline: none;
        line-height: 1.5;
        border: none;
        background-color: #fff;
        overflow-wrap: break-word;
        white-space: pre-wrap;

        &::-webkit-list-button {
            display: none !important;
        }
    }

    [list]::-webkit-calendar-picker-indicator {
        display: none !important;
    }
}
