.mobile-message-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6rem 2rem;

    .main-message-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;

        p {
            padding: 0;
            margin: 0;
            will-change: transform, opacity;
            width: 100%;
            text-align: center;
            max-width: 500px;
        }

        .title {
            font-family: var(--ibm-plex-serif);
            font-size: 28px;
            letter-spacing: -1px;
            margin-top: 40px;
        }

        .desc {
            font-family: var(--inter);
            font-size: 16px;
            line-height: 1.65;
            margin-top: 20px;
            letter-spacing: 0.1px;

            u {
                text-decoration-thickness: 1px;
                text-underline-position: under;
                text-underline-offset: 1px;
            }
        }
    }

    .mobile-message-art {
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: center;
        align-items: flex-end;
        user-select: none;

        .mangocopy-symbol {
            height: 70px;
            width: 70px;
            pointer-events: none;
        }
    }
}
