header {
    height: var(--header-height);
    width: 100%;
    position: sticky;
    top: 0;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 0.85);
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 12;
    padding: 0 40px;

    @media (max-width: 1000px) {
        padding: 0 30px;
    }

    .header-logo {
        line-height: 1;
        height: 100%;
        display: flex;
        align-items: center;
        outline-color: #ddd;
        outline-offset: 2px;

        img {
            pointer-events: none;
            user-select: none;
        }
    }

    .nav-toggle-button {
        display: none;
    }

    @media (max-width: 1000px) {
        .nav-toggle-button {
            --size: 40px;
            display: flex;
            border: none;
            background-color: #fff;
            height: var(--size);
            width: var(--size);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 500px;
            user-select: none;
            transition: all 0.2s var(--bounce-ease-1);

            &:active {
                transform: scale(0.85);
            }

            img {
                --icon-size: 24px;
                height: var(--icon-size);
                width: var(--icon-size);
                pointer-events: none;
            }

            &.active {
                background-color: #eee;
            }
        }
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        @media (max-width: 1000px) {
            display: none;

            &.overflow {
                display: flex;
                position: fixed;
                left: 0;
                top: var(--header-height);
                background-color: #fff;
                flex-direction: column;
                width: 100%;
                height: auto;
                border-bottom: 1px solid #ddd;
                box-shadow: 0 35px 80px -20px rgb(0 0 10 / 5%), 0 30px 60px -30px rgb(0 0 0 / 15%);
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                padding: 10px 20px;
                animation: nav-overflow-intro 0.6s var(--bounce-ease-1) forwards;

                @keyframes nav-overflow-intro {
                    from {
                        transform: translateY(-5%);
                        opacity: 0;
                    }
                    to {
                        transform: translateY(0%);
                        opacity: 1;
                    }
                }

                a {
                    width: 100%;
                    font-size: 16px;
                    padding: 20px;
                    padding-left: 10px;
                    background-color: #fff;
                    opacity: 1;
                    user-select: none;
                    border-bottom: 1px solid #eee;

                    &:last-child {
                        border-bottom: none;
                    }

                    &.active::before {
                        left: auto;
                        bottom: auto;
                        right: 18px;
                        width: 5px;
                        height: 5px;
                        border-radius: 500px;
                        transition: none;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        height: 20px;
                        width: 20px;
                        right: 10px;
                        background: url("../../images/icons/arrow_right.svg");
                        background-position: center;
                        background-repeat: no-repeat;
                        opacity: 0.25;
                    }

                    &.active::after {
                        display: none;
                    }
                }
            }
        }

        a {
            font-family: var(--inter);
            display: flex;
            align-items: center;
            padding: 15px;
            height: 100%;
            text-decoration: none;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.15px;
            position: relative;
            color: #000;
            opacity: 0.65;
            transition: all 0.2s ease-out;
            outline-color: #ddd;
            outline-offset: 2px;

            &::before {
                content: "";
                width: 100%;
                background-color: var(--mango-green);
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                opacity: 0;
                transition: all 0.2s ease-out;
            }

            &:hover,
            &:focus {
                opacity: 1;
            }

            &.active {
                &::before {
                    opacity: 1;
                }
            }
        }

        .logout-btn {
            border: none;
            background-color: rgba(255, 255, 255, 0);
            height: 100%;
            padding: 0 10px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 500px;
            overflow: hidden;

            &:hover,
            &:focus-visible {
                outline: none;

                img {
                    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ddd;
                }
            }

            img {
                height: 24px;
                width: 24px;
                border-radius: 500px;
                user-select: none;
                pointer-events: none;
            }
        }
    }
}

.nav-skrim {
    position: fixed;
    inset: 0;
    z-index: 11;
    background-color: rgba(255, 255, 255, 0.85);
}
