:root {
  --ibm-plex-serif: "IBM Plex Serif", Georgia, serif;
  --inter: "Inter", sans-serif;

  font-family: var(--inter);
  font-size: 12px;
  --mango-orange: #ffa02e;
  --mango-orange-light: #ffcf94;
  --mango-orange-dark: #f58700;
  --mango-green: #336600;
  --mango-green-light: #bbe293;
  --header-height: 45px;

  --bounce-ease-1: cubic-bezier(0.4, -0.01, 0, 1.31);

  @media (max-width: 1000px){
    --header-height: 60px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  line-height: 1;
  line-height: 1.35;
}

h1,
h2,
h3 {
  font-family: var(--ibm-plex-serif);
}

h1 {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -1.5px;
  padding: 8px 0;
  line-height: 1.25;
}

h2 {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -0.75px;
  padding: 8px 0;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.1px;
  padding: 12px 0;
}

a {
  color: inherit;
}

p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

@media (max-width: 1000px) {
  h1 {
    font-size: 28px;
    letter-spacing: -1px;
  }

  h2 {
    font-size: 20px;
    letter-spacing: -0.25px;
  }

  h3 {
    font-size: 16px;
  }
}
